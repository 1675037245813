import React, { useState, useEffect } from "react"
import * as queryString from "query-string"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import { sendContact } from "../services/contact"
import UseSiteMetadata from "../components/UseSiteMetadata"

const regExEmail = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
)

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const [userName, setUserName] = useState("")
  const [company, setCompany] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [servInformativeWeb, setServInformativeWeb] = useState(false)
  const [servBlogNewsWeb, setServBlogNewsWeb] = useState(false)
  const [servWebApp, setServWebApp] = useState(false)
  const [servSocialMedia, setServSocialMedia] = useState(false)
  const [servGraphicDesign, setServGraphicDesign] = useState(false)
  const [errorFields, setErrorFields] = useState([])

  const [showSpinner, setShowSpinner] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [sendDisabled, setSendDisabled] = useState(true)
  const [urlValue, setUrlValue] = useState(true)

  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "en-US",

      },
    ],
  }

  useEffect(() => {
    if (urlValue) {
      const serviceUrl = queryString.parse(location.search)["service"]
      switch (serviceUrl) {
        case "informative-website":
          setServInformativeWeb(!servInformativeWeb)
          break
        case "blog-news-website":
          setServBlogNewsWeb(!servBlogNewsWeb)
          break
        case "web-app":
          setServWebApp(!servWebApp)
          break
        case "social-media":
          setServSocialMedia(!servSocialMedia)
          break
        case "graphic-design":
          setServGraphicDesign(!servGraphicDesign)
          break
        default:
          break
      }
    }
    setUrlValue(false)
  }, [
    location,
    servInformativeWeb,
    servBlogNewsWeb,
    servWebApp,
    servSocialMedia,
    servGraphicDesign,
    urlValue,
  ])

  const servicesAdjust = service => {
    switch (service) {
      case "informative-website":
        setServInformativeWeb(!servInformativeWeb)
        break
      case "blog-news-website":
        setServBlogNewsWeb(!servBlogNewsWeb)
        break
      case "website-app":
        setServWebApp(!servWebApp)
        break
      case "social-media":
        setServSocialMedia(!servSocialMedia)
        break
      case "graphic-design":
        setServGraphicDesign(!servGraphicDesign)
        break
      default:
        break
    }
  }

  const changeUserName = evt => {
    setUserName(evt.target.value)
    if (evt.target.value !== "") {
      removeError("userName")
    }
  }

  const changeCompany = evt => {
    setCompany(evt.target.value)
    if (evt.target.value !== "") {
      removeError("company")
    }
  }

  const changePhoneNumber = evt => {
    setPhoneNumber(evt.target.value)
    if (evt.target.value !== "") {
      removeError("phoneNumber")
    }
  }

  const changeEmail = evt => {
    setEmail(evt.target.value.trim())
    if (regExEmail.test(evt.target.value.trim())) {
      removeError("email")
    }
  }

  const changeMessage = evt => {
    setMessage(evt.target.value)
    if (evt.target.value !== "") {
      removeError("message")
    }
  }

  const sendEmail = evt => {
    verifyFields()
  }

  const removeError = errName => {
    if (errorFields.includes(errName)) {
      let errorFieldAux = errorFields
      errorFieldAux.splice(errorFieldAux.indexOf(errName), 1)
      setErrorFields(errorFieldAux)
    }
  }

  const verifyFields = () => {
    let errorFieldAux = []
    if (userName === "") {
      errorFieldAux.push("userName")
    }
    if (!regExEmail.test(email)) {
      errorFieldAux.push("email")
    }
    if (message === "") {
      errorFieldAux.push("message")
    }
    if (errorFieldAux.length !== 0) {
      setErrorFields(errorFieldAux)
      scrollToError(errorFieldAux[0])
    } else {
      setShowSpinner(true)
      handleSendContact()
    }
  }

  const scrollToError = filedId => {
    const element = document.getElementById(filedId)
    const screenPosition = element.getBoundingClientRect()
    console.log(screenPosition.top)
    console.log(window.scrollY)
    console.log(window.scrollY + screenPosition.top)
    window.scrollTo({
      top: window.scrollY + screenPosition.top - 126,
      left: 0,
      behavior: "smooth",
    })

    // element.scrollIntoView({ behavior: "smooth" })
  }

  const handleSendContact = async () => {
    const data = {
      userName: userName,
      company: company,
      phoneNumber: phoneNumber,
      email: email,
      message: message,
    }
    let services = []
    const allServices = [
      [servInformativeWeb, "informative website"],
      [servBlogNewsWeb, "blog/news website"],
      [servWebApp, "website app"],
      [servSocialMedia, "social media"],
      [servGraphicDesign, "graphic design"],
    ]
    allServices.forEach(element => {
      if (element[0]) {
        services.push(element[1])
      }
    })
    data["services"] = services
    setShowSpinner(true)
    await sendContact(data).then(res => {
      setShowModal(true)
      setShowSpinner(false)
      console.log(res)
    })
    console.log(data)
  }
  const closeModal = () => {
    setShowModal(false)
    setUserName("")
    setCompany("")
    setPhoneNumber("")
    setEmail("")
    setMessage("")
    setServInformativeWeb(false)
    setServBlogNewsWeb(false)
    setServWebApp(false)
    setServSocialMedia(false)
    setServGraphicDesign(false)
    setSendDisabled(true)
  }

  const changeDisabled = e => {
    setSendDisabled(!sendDisabled)
  }

  const setService = e => {
    servicesAdjust(e.target.id)
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | lets work together"
        description="lets work together"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websitesites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <div className={`${showModal ? "block" : "hidden"}`}>
        <div
          id="defaultModal"
          className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full "
        >
          <div className="relative p-4 w-full max-w-2xl h-full md:h-auto m-auto">
            <div className="relative rounded-lg shadow bg-nb-blue border border-blue-600 ">
              <div className="p-6 space-y-6">
                <p className=" text-xl leading-relaxed text-white text-center">
                  Your email was sent successfully.
                  <br />
                  Thanks.
                </p>
                <div className="w-max mx-auto">
                  <button
                    onClick={env => closeModal()}
                    className=" btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 "
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-10">
              <h1>Let's work together</h1>
            </div>
          </div>
          <div className="h-8" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-4" />
            <div className="col-span-6">
              <span className="text-lg">
                select services:
                <br />
                &nbsp;
              </span>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-4">
                  <input
                    id="informative-website"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 accent-blue-600 "
                    onChange={env => setService(env)}
                    checked={servInformativeWeb}
                  />
                  <label htmlFor="default-checkbox" className="ml-2">
                    informative website
                  </label>
                </div>
                <div className="col-span-4">
                  <input
                    id="blog-news-website"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 accent-blue-600 "
                    onChange={env => setService(env)}
                    checked={servBlogNewsWeb}
                  />
                  <label htmlFor="default-checkbox" className="ml-2">
                    blog/news website
                  </label>
                </div>
                <div className="col-span-4">
                  <input
                    id="web-app"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 accent-blue-600 "
                    onChange={env => setService(env)}
                    checked={servWebApp}
                  />
                  <label htmlFor="default-checkbox" className="ml-2">
                    web app
                  </label>
                </div>
              </div>
              <div className=" h-4 md:h-0" />
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-4">
                  <input
                    id="social-media"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 accent-blue-600 "
                    onChange={env => setService(env)}
                    checked={servSocialMedia}
                  />
                  <label htmlFor="default-checkbox" className="ml-2">
                    social media
                  </label>
                </div>
                <div className="col-span-4">
                  <input
                    id="graphic-design"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 accent-blue-600 "
                    onChange={env => setService(env)}
                    checked={servGraphicDesign}
                  />
                  <label htmlFor="default-checkbox" className="ml-2">
                    graphic design
                  </label>
                </div>
              </div>

              <div className="h-8" />
              <div className="grid grid-cols-2 md:grid-cols-2">
                <div className="col-span-2  ">
                  <div className="w-full  mb-6 md:mb-0">
                    <label className="block text-base mb-2" htmlFor="grid-name">
                      Name
                    </label>
                    <input
                      className={`${
                        errorFields.includes("userName") ? "border-red-800" : ""
                      } appearance-none block w-full bg-gray-200 text-gray-900 font-medium border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      id="userName"
                      type="text"
                      onChange={env => changeUserName(env)}
                      value={userName}
                    />
                  </div>
                </div>
                <div className="col-span-2 ">
                  <div className="w-full pr-0 md:pr-1 mb-6 md:mb-0">
                    <label className="block text-base mb-2" htmlFor="grid-name">
                      email
                    </label>
                    <input
                      className={`${
                        errorFields.includes("email") ? "border-red-800" : ""
                      } appearance-none block w-full bg-gray-200 text-gray-900 font-medium border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      id="email"
                      type="text"
                      onChange={env => changeEmail(env)}
                      value={email}
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 ">
                  <div className="w-full pr-0 md:pr-1 mb-6 md:mb-0">
                    <label className="block text-base mb-2" htmlFor="grid-name">
                      Company
                    </label>
                    <input
                      className={`${
                        errorFields.includes("company") ? "border-red-800" : ""
                      } appearance-none block w-full bg-gray-200 text-gray-900 font-medium border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      id="company"
                      type="text"
                      onChange={env => changeCompany(env)}
                      value={company}
                    />
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 ">
                  <div className="w-full pl-0 md:pl-1 mb-6 md:mb-0">
                    <label className="block text-base mb-2" htmlFor="grid-name">
                      Phone number
                    </label>
                    <input
                      className={`${
                        errorFields.includes("phoneNumber")
                          ? "border-red-800"
                          : ""
                      } appearance-none block w-full bg-gray-200 text-gray-900 font-medium border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      id="phoneNumber"
                      type="text"
                      onChange={env => changePhoneNumber(env)}
                      value={phoneNumber}
                    />
                  </div>
                </div>

                <div className="col-span-2  ">
                  <div className="w-full pl-0 md:pl-1 mb-6 md:mb-0">
                    <label className="block text-base mb-2" htmlFor="grid-name">
                      Message
                    </label>
                    <textarea
                      className={`${
                        errorFields.includes("message") ? "border-red-800" : ""
                      } appearance-none block w-full bg-gray-200 text-gray-900 font-medium border-2  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white`}
                      id="message"
                      type="text"
                      onChange={env => changeMessage(env)}
                      value={message}
                      rows={4}
                    />
                  </div>
                </div>

                <div className="col-span-2 ">
                  <div className="grid grid-cols-12 ">
                    <div className="col-span-1 pl-0 md:pl-1 mb-1 md:mb-0 ">
                      <label
                        htmlFor="default-toggle"
                        className="inline-flex relative items-center cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={!sendDisabled}
                          id="default-toggle"
                          className="sr-only peer"
                          onChange={evt => changeDisabled(evt)}
                        />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer  peer-checked:after:translate-x-full  after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-nb-blue  after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600" />
                      </label>
                    </div>
                    <div className="col-span-10 text-base  pl-6">
                      I agree to provide the above data for the purpose of
                      communicating with NetBistrot.
                    </div>
                  </div>
                </div>

                <div className="col-span-2 md:col-span-2">
                  <div className="block py-3 ">
                    <button
                      className={`${
                        sendDisabled
                          ? "btn-icon rounded text-lg text-nb-blue bg-blue-400  focus:outline-none focus:ring px-3 py-1.5 float-left"
                          : "btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-3 py-1.5 float-left"
                      }`}
                      onClick={evt => sendEmail(evt)}
                      disabled={sendDisabled}
                    >
                      send
                    </button>
                    <div
                      role="status"
                      className={`${
                        showSpinner ? "" : "hidden"
                      } mt-1 float-left`}
                    >
                      <svg
                        aria-hidden="true"
                        className="mr-2 w-8 h-8 text-sc-gray animate-spin fill-nb-blue"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#ffffff"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#171941"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
